import clsx from 'clsx';
import React, { FC } from 'react';

import * as svgIcons from './icons-list';
import type { IconName } from './icons.type';

const icons = Object(svgIcons);

type IconProps = {
  className?: string;
  name: IconName;
  color?: string | 'neutral_b';
  size?: number;
  disabled?: string;
};

const Icon: FC<IconProps> = (props) => {
  const { name, color = 'neutral_b', disabled, size = 24, ...remainingProps } = props;
  const iconObject = Object(icons);
  const Elem = iconObject[name];
  const currentColor = disabled || `text-${color}`;

  const renderIcon = () => {
    return <Elem size={size} fill={color} {...remainingProps} />;
  };

  return (
    <div
      className={clsx(currentColor, 'flex justify-center items-center')}
      style={{
        width: `${size}px`,
        maxHeight: `${size}px`,
        display: 'flex',
        alignItems: 'center',
      }}
      {...remainingProps}
    >
      {renderIcon()}
    </div>
  );
};

export default Icon;
